@import url("https://fonts.googleapis.com/css2?family=Italiana&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* html {
  overflow-x: visible;
} */

@font-face {
  font-family: "helvetica";
  src: url("../public/fonts/HelveticaNeue/HelveticaNeueLTStd-UltLt.otf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "helvetica";
  src: url("../public/fonts/HelveticaNeue/HelveticaNeueLTStd-Lt.otf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "helvetica";
  src: url("../public/fonts/HelveticaNeue/HelveticaNeueLTStd-Bd.otf");
  font-weight: 1000;
  font-style: normal;
  font-display: swap;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke-width: 2;
  stroke-linecap: round;
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham6 .top {
  stroke-dasharray: 40 172;
}
.ham6 .middle {
  stroke-dasharray: 40 111;
}
.ham6 .bottom {
  stroke-dasharray: 40 172;
}
.ham6.active .top {
  stroke-dashoffset: -132px;
}
.ham6.active .middle {
  stroke-dashoffset: -71px;
}
.ham6.active .bottom {
  stroke-dashoffset: -132px;
}

.location-europe,
.world {
  stroke: #9d8e68;
  stroke-width: 1px;
}
.europe,
.location-world,
.location-world-second {
  stroke: #9d8e68;
  stroke-width: 2px;
}

.overlay {
  grid-area: 1 / 1 / 2 / 2;
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  width: 100vw;
  height: 100vh;
}
